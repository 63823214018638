import Box from '@mui/material/Box';
import KampusBadge from 'components/shared/KampusBadge';
import { loginRedirectUrl } from 'features/auth/functions';
import { selectedClientSelector } from 'features/client/selectors';
import { userSelector } from 'features/user/selectors';
import { useAppSelector } from 'store/hooks';

export type KampusDrawerHeaderProps = {
  mini?: boolean;
};

function KampusDrawerHeader({ mini = false }: KampusDrawerHeaderProps) {
  const user = useAppSelector(userSelector);
  const selectedClient = useAppSelector(selectedClientSelector);

  const { id: selectedClientId } = selectedClient ?? {};

  // When a selectedClientId is not present, home redirects to whatever
  // this user would see after login. It makes the kortx button always
  // lead to a meaningful page.
  const linkToHome =
    !selectedClientId && user
      ? loginRedirectUrl(user)
      : `/clients/${selectedClientId}/home`;

  return (
    <Box
      sx={{
        height: '4rem',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        columnGap: (theme) => theme.spacing(1.5),
        paddingRight: (theme) => theme.spacing(3),
        paddingLeft: (theme) => theme.spacing(3),
      }}
    >
      <KampusBadge size="small" linkTo={linkToHome} showLogotype={!mini} />
    </Box>
  );
}

export default KampusDrawerHeader;
