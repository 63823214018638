/**
 * "Safer" String.toLowerCase()
 */
export const toLowerCase = (value: string | undefined) => {
  if (value === undefined) {
    return '';
  }

  return value.toLowerCase();
};

/**
 * "Safer" String.toUpperCase()
 */
export const toUpperCase = (value: string | undefined) => {
  if (value === undefined) {
    return '';
  }

  return value.toUpperCase();
};

export const equalsIgnoreCase = (
  value: string | undefined,
  valueToCompare: string | undefined
) => {
  if (value === undefined || valueToCompare === undefined) {
    return value === valueToCompare;
  }

  return (
    value.localeCompare(valueToCompare, undefined, {
      sensitivity: 'accent',
    }) === 0
  );
};

/**
 * Replaces all accented chars with regular ones
 */
export const replaceAccents = (value: string | undefined) => {
  if (value === undefined) {
    return '';
  }

  if (value.search(/[\u00C0-\u00FF]/g) > -1) {
    return value
      .replace(/[\u00C0-\u00C5]/g, 'A')
      .replace(/\u00C6/g, 'AE')
      .replace(/\u00C7/g, 'C')
      .replace(/[\u00C8-\u00CB]/g, 'E')
      .replace(/[\u00CC-\u00CF]/g, 'I')
      .replace(/\u00D0/g, 'D')
      .replace(/\u00D1/g, 'N')
      .replace(/[\u00D2-\u00D6\u00D8]/g, 'O')
      .replace(/[\u00D9-\u00DC]/g, 'U')
      .replace(/\u00DD/g, 'Y')
      .replace(/\u00DE/g, 'P')
      .replace(/[\u00E0-\u00E5]/g, 'a')
      .replace(/\u00E6/g, 'ae')
      .replace(/\u00E7/g, 'c')
      .replace(/[\u00E8-\u00EB]/g, 'e')
      .replace(/[\u00EC-\u00EF]/g, 'i')
      .replace(/\u00F1/g, 'n')
      .replace(/[\u00F2-\u00F6\u00F8]/g, 'o')
      .replace(/[\u00F9-\u00FC]/g, 'u')
      .replace(/\u00FE/g, 'p')
      .replace(/[\u00FD\u00FF]/g, 'y');
  }

  return value;
};

/**
 * Remove spaces
 */
export const removeSpaces = (value: string | undefined) => {
  if (value === undefined) {
    return '';
  }

  return value.replace(/\s/g, '');
};

/**
 * Remove non-alphanumeric chars.
 */
export const removeNonAlphanumeric = (value: string | undefined) => {
  if (value === undefined) {
    return '';
  }

  return value.replace(/[^\da-z]/gi, '');
};

/**
 * Remove non-word chars.
 */
export const removeNonWord = (value: string | undefined) => {
  if (value === undefined) {
    return '';
  }

  return value.replace(/[^\d A-Za-z\u00C0-\u00FF-]/g, '');
};

/**
 * Used to convert role to more human readable format
 */
export const toHuman = (text: string) =>
  text
    .toLowerCase()
    .split(/[_\s-]/gi)
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ');

/**
 * to camelCase
 */
export const toCamelCase = (value: string | undefined) => {
  if (value === undefined) {
    return '';
  }

  return removeNonWord(replaceAccents(value))
    .replace(/-/g, ' ') // convert all hyphens to spaces
    .replace(/\s[a-z]/g, toUpperCase) // convert first char of each word to UPPERCASE
    .replace(/\s+/g, '') // remove spaces
    .replace(/^[A-Z]/g, toLowerCase); // convert first char to lowercase
};

/**
 * to kebab-case
 */
export const toKebabCase = (value: string | undefined) => {
  if (value === undefined) {
    return '';
  }

  return removeNonWord(replaceAccents(value))
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-') // replace spaces with hyphens
    .toLowerCase(); // convert first char to lowercase
};

/**
 * to Title Case
 */
export const toTitleCase = (value: string) => {
  return replaceAccents(value)
    .replace(/_/g, ' ')
    .replace(
      /\w\S*/g,
      (value) => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
    );
};

export const capitalizeFirstLetter = (value: string | undefined) => {
  if (value === undefined) {
    return '';
  }

  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const uncapitalizeFirstLetter = (value: string | undefined) => {
  if (value === undefined) {
    return '';
  }

  return value.charAt(0).toLowerCase() + value.slice(1);
};

export const usdCurrencyNumberFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
});

export const isNumeric = (value: string | number) =>
  !Number.isNaN(value) &&
  !Number.isNaN(Number.parseFloat(value as unknown as string));

export const castToNumber = (value: string | number | undefined) => {
  if (value === undefined) {
    return undefined;
  }

  if (isNumeric(value)) {
    return Number(value);
  }

  return undefined;
};

export const formatNumberWithCommas = (value: number) => {
  if (!isNumeric(value)) {
    return null;
  }

  return Math.round(value)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const isEmailRegex =
  /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/;

export const isSimpleDateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;

export function urlToBase64(url: string) {
  try {
    return encodeURIComponent(btoa(url));
  } catch {
    return undefined;
  }
}

export function base64ToUrl(base64Url: string) {
  try {
    return atob(decodeURIComponent(base64Url));
  } catch {
    return undefined;
  }
}

export const EMAIL_REGEX =
  /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/;
