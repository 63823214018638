import {
  CampaignDto,
  CampaignDtoStatusEnum,
  CampaignFileDto,
  CampaignHealthEventDto,
  FileProperties,
  ProvenProcessStepInfoDto,
} from '@kortxio/hub-api';
import { RequestState } from 'features/shared/request';
import { ObjectValues } from 'libs/types/types';

export const CAMPAIGN_TAB = {
  Overview: 'overview',
  Documents: 'documents',
} as const;

export type CampaignTab = ObjectValues<typeof CAMPAIGN_TAB>;

export const DOCUMENT_FILE_TYPE_FILTER_LABELS = {
  All: 'All',
  Document: 'Document',
  Image: 'Image',
  Spreadsheet: 'Spreadsheet',
  PDF: 'PDF',
  Other: 'Other',
} as const;

export type FlattenedCampaignFile = CampaignFileDto & FileProperties;

export type DocumentFileTypeFilterLabel = ObjectValues<
  typeof DOCUMENT_FILE_TYPE_FILTER_LABELS
>;

export type BudgetGroupOrLineItemForCampaignsByIdPage = {
  id?: number;
  name?: string;
  type?: string;
  product?: string;
  startDate?: string;
  endDate?: string;
  rate?: number;
  impressions?: number;
  budget?: number;
};

export type CampaignsForCampaignByIdPage = {
  id?: number;
  campaignName?: string;
  advertiserId?: number;
  advertiserName?: string;
  advertiserUrl?: string;
  status?: CampaignDtoStatusEnum;
  startDate?: string;
  endDate?: string;
  daysRemaining: number;
  budget?: number;
  kpi?: string;
  adserver?: string;
  landingPage?: string;
  markdownInsertionOrderNotes?: string;
  contact: {
    lastName?: string;
    firstName?: string;
    email?: string;
  };
  campaignHealthEvents?: CampaignHealthEventDto[];
};

export type CampaignByIdUiState = {
  searchForDocuments: string | undefined;
  fileTypeFilterLabel: DocumentFileTypeFilterLabel;
};

export type CampaignByIdState = {
  selectedCampaign: {
    data: CampaignDto | undefined;
    request: RequestState;
  };
  provenProcess: {
    data: ProvenProcessStepInfoDto | undefined;
    request: RequestState;
  };
  documents: {
    data: CampaignFileDto[] | undefined;
    request: RequestState;
  };
  ui: CampaignByIdUiState;
};
