/* eslint-disable */
import { apiExtensions as api } from '../rtk/apiExtensions';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAccounts: build.query<GetAccountsApiResponse, GetAccountsApiArg>({
      query: (queryArg) => ({
        url: `/accounts`,
        params: {
          accessToken: queryArg.accessToken,
          expiresInSeconds: queryArg.expiresInSeconds,
          sourceType: queryArg.sourceType,
        },
      }),
    }),
    getConnectionById: build.query<
      GetConnectionByIdApiResponse,
      GetConnectionByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/connections/${queryArg['connection-id']}`,
        headers: { 'X-Impersonated-By': queryArg['X-Impersonated-By'] },
      }),
    }),
    updateConnection: build.mutation<
      UpdateConnectionApiResponse,
      UpdateConnectionApiArg
    >({
      query: (queryArg) => ({
        url: `/connections/${queryArg['connection-id']}`,
        method: 'PUT',
        body: queryArg.sourceInput,
        headers: { 'X-Impersonated-By': queryArg['X-Impersonated-By'] },
      }),
    }),
    deleteConnection: build.mutation<
      DeleteConnectionApiResponse,
      DeleteConnectionApiArg
    >({
      query: (queryArg) => ({
        url: `/connections/${queryArg['connection-id']}`,
        method: 'DELETE',
        headers: { 'X-Impersonated-By': queryArg['X-Impersonated-By'] },
        params: { purge: queryArg.purge },
      }),
    }),
    purgeConnection: build.mutation<
      PurgeConnectionApiResponse,
      PurgeConnectionApiArg
    >({
      query: (queryArg) => ({
        url: `/connections/${queryArg['connection-id']}/purge`,
        method: 'POST',
        headers: { 'X-Impersonated-By': queryArg['X-Impersonated-By'] },
      }),
    }),
    listUnlinkedManualConnections: build.query<
      ListUnlinkedManualConnectionsApiResponse,
      ListUnlinkedManualConnectionsApiArg
    >({
      query: (queryArg) => ({
        url: `/manual-connections/unlinked`,
        params: { 'source-type': queryArg['source-type'] },
      }),
    }),
    getAccessTokenForService: build.query<
      GetAccessTokenForServiceApiResponse,
      GetAccessTokenForServiceApiArg
    >({
      query: (queryArg) => ({
        url: `/oauth/accessToken`,
        params: {
          sourceType: queryArg.sourceType,
          refreshToken: queryArg.refreshToken,
        },
      }),
    }),
    getCredentialsForService: build.query<
      GetCredentialsForServiceApiResponse,
      GetCredentialsForServiceApiArg
    >({
      query: (queryArg) => ({
        url: `/oauth/credentials`,
        params: {
          sourceType: queryArg.sourceType,
          redirectUrl: queryArg.redirectUrl,
          code: queryArg.code,
        },
      }),
    }),
    whoami: build.query<WhoamiApiResponse, WhoamiApiArg>({
      query: () => ({ url: `/oauth/me` }),
    }),
    getOAuthUrl: build.query<GetOAuthUrlApiResponse, GetOAuthUrlApiArg>({
      query: (queryArg) => ({
        url: `/oauth/url`,
        params: {
          sourceType: queryArg.sourceType,
          redirectUrl: queryArg.redirectUrl,
        },
      }),
    }),
    getTenant: build.query<GetTenantApiResponse, GetTenantApiArg>({
      query: () => ({ url: `/tenant` }),
    }),
    listWorkspaces: build.query<
      ListWorkspacesApiResponse,
      ListWorkspacesApiArg
    >({
      query: (queryArg) => ({
        url: `/workspaces`,
        headers: { 'X-Impersonated-By': queryArg['X-Impersonated-By'] },
        params: { 'alternate-id': queryArg['alternate-id'] },
      }),
    }),
    createWorkspace: build.mutation<
      CreateWorkspaceApiResponse,
      CreateWorkspaceApiArg
    >({
      query: (queryArg) => ({
        url: `/workspaces`,
        method: 'POST',
        body: queryArg.workspaceInput,
        headers: { 'X-Impersonated-By': queryArg['X-Impersonated-By'] },
      }),
    }),
    getWorkspaceById: build.query<
      GetWorkspaceByIdApiResponse,
      GetWorkspaceByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/workspaces/${queryArg['workspace-id']}`,
        headers: { 'X-Impersonated-By': queryArg['X-Impersonated-By'] },
      }),
    }),
    updateWorkspace: build.mutation<
      UpdateWorkspaceApiResponse,
      UpdateWorkspaceApiArg
    >({
      query: (queryArg) => ({
        url: `/workspaces/${queryArg['workspace-id']}`,
        method: 'PUT',
        body: queryArg.workspaceInput,
        headers: { 'X-Impersonated-By': queryArg['X-Impersonated-By'] },
      }),
    }),
    deleteWorkspace: build.mutation<
      DeleteWorkspaceApiResponse,
      DeleteWorkspaceApiArg
    >({
      query: (queryArg) => ({
        url: `/workspaces/${queryArg['workspace-id']}`,
        method: 'DELETE',
        headers: { 'X-Impersonated-By': queryArg['X-Impersonated-By'] },
      }),
    }),
    listConnectionsByWorkspace: build.query<
      ListConnectionsByWorkspaceApiResponse,
      ListConnectionsByWorkspaceApiArg
    >({
      query: (queryArg) => ({
        url: `/workspaces/${queryArg['workspace-id']}/connections`,
        headers: { 'X-Impersonated-By': queryArg['X-Impersonated-By'] },
      }),
    }),
    createConnection: build.mutation<
      CreateConnectionApiResponse,
      CreateConnectionApiArg
    >({
      query: (queryArg) => ({
        url: `/workspaces/${queryArg['workspace-id']}/connections/create`,
        method: 'POST',
        body: queryArg.sourceInput,
        headers: { 'X-Impersonated-By': queryArg['X-Impersonated-By'] },
      }),
    }),
    getConnectionInWorkspace: build.query<
      GetConnectionInWorkspaceApiResponse,
      GetConnectionInWorkspaceApiArg
    >({
      query: (queryArg) => ({
        url: `/workspaces/${queryArg['workspace-id']}/connections/${queryArg['connection-id']}`,
        headers: { 'X-Impersonated-By': queryArg['X-Impersonated-By'] },
      }),
    }),
    updateConnectionFromWorkspace: build.mutation<
      UpdateConnectionFromWorkspaceApiResponse,
      UpdateConnectionFromWorkspaceApiArg
    >({
      query: (queryArg) => ({
        url: `/workspaces/${queryArg['workspace-id']}/connections/${queryArg['connection-id']}`,
        method: 'PUT',
        body: queryArg.sourceInput,
        headers: { 'X-Impersonated-By': queryArg['X-Impersonated-By'] },
      }),
    }),
    deleteConnectionFromWorkspace: build.mutation<
      DeleteConnectionFromWorkspaceApiResponse,
      DeleteConnectionFromWorkspaceApiArg
    >({
      query: (queryArg) => ({
        url: `/workspaces/${queryArg['workspace-id']}/connections/${queryArg['connection-id']}`,
        method: 'DELETE',
        headers: { 'X-Impersonated-By': queryArg['X-Impersonated-By'] },
        params: { purge: queryArg.purge },
      }),
    }),
    purgeConnectionInWorkspace: build.mutation<
      PurgeConnectionInWorkspaceApiResponse,
      PurgeConnectionInWorkspaceApiArg
    >({
      query: (queryArg) => ({
        url: `/workspaces/${queryArg['workspace-id']}/connections/${queryArg['connection-id']}/purge`,
        method: 'POST',
        headers: { 'X-Impersonated-By': queryArg['X-Impersonated-By'] },
      }),
    }),
    listDashboardsByWorkspace: build.query<
      ListDashboardsByWorkspaceApiResponse,
      ListDashboardsByWorkspaceApiArg
    >({
      query: (queryArg) => ({
        url: `/workspaces/${queryArg['workspace-id']}/dashboards`,
        headers: { 'X-Impersonated-By': queryArg['X-Impersonated-By'] },
      }),
    }),
    listDataSourcesByWorkspace: build.query<
      ListDataSourcesByWorkspaceApiResponse,
      ListDataSourcesByWorkspaceApiArg
    >({
      query: (queryArg) => ({
        url: `/workspaces/${queryArg['workspace-id']}/data-sources`,
        headers: { 'X-Impersonated-By': queryArg['X-Impersonated-By'] },
      }),
    }),
    listManualConnectionsByWorkspace: build.query<
      ListManualConnectionsByWorkspaceApiResponse,
      ListManualConnectionsByWorkspaceApiArg
    >({
      query: (queryArg) => ({
        url: `/workspaces/${queryArg['workspace-id']}/manual-connections`,
        headers: { 'X-Impersonated-By': queryArg['X-Impersonated-By'] },
      }),
    }),
    createManualConnection: build.mutation<
      CreateManualConnectionApiResponse,
      CreateManualConnectionApiArg
    >({
      query: (queryArg) => ({
        url: `/workspaces/${queryArg['workspace-id']}/manual-connections`,
        method: 'POST',
        body: queryArg.manualConnectionInput,
        headers: { 'X-Impersonated-By': queryArg['X-Impersonated-By'] },
      }),
    }),
    updateManualConnectionFromWorkspace: build.mutation<
      UpdateManualConnectionFromWorkspaceApiResponse,
      UpdateManualConnectionFromWorkspaceApiArg
    >({
      query: (queryArg) => ({
        url: `/workspaces/${queryArg['workspace-id']}/manual-connections/${queryArg['manual-connection-id']}`,
        method: 'PUT',
        body: queryArg.manualConnectionInput,
        headers: { 'X-Impersonated-By': queryArg['X-Impersonated-By'] },
      }),
    }),
    deleteManualConnectionFromWorkspace: build.mutation<
      DeleteManualConnectionFromWorkspaceApiResponse,
      DeleteManualConnectionFromWorkspaceApiArg
    >({
      query: (queryArg) => ({
        url: `/workspaces/${queryArg['workspace-id']}/manual-connections/${queryArg['manual-connection-id']}`,
        method: 'DELETE',
        headers: { 'X-Impersonated-By': queryArg['X-Impersonated-By'] },
      }),
    }),
    queryWorkspace: build.mutation<
      QueryWorkspaceApiResponse,
      QueryWorkspaceApiArg
    >({
      query: (queryArg) => ({
        url: `/workspaces/${queryArg['workspace-id']}/query`,
        method: 'POST',
        body: queryArg.body,
        headers: { 'X-Impersonated-By': queryArg['X-Impersonated-By'] },
        params: { tag: queryArg.tag },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as innerAnalyticsApi };
export type GetAccountsApiResponse = /** status 200 Ok */ AccountMapDto;
export type GetAccountsApiArg = {
  accessToken?: string | null;
  expiresInSeconds?: number | null;
  sourceType: SourceType;
};
export type GetConnectionByIdApiResponse =
  /** status 200 getConnectionById 200 response */ ConnectionOutput;
export type GetConnectionByIdApiArg = {
  /** The presence of this header, coming from an account with the CAN_IMPERSONATE permission, will cause the request to be executed as if it were coming from the account with the id specified */
  'X-Impersonated-By'?: any;
  'connection-id': number;
};
export type UpdateConnectionApiResponse =
  /** status 200 updateConnection 200 response */ ConnectionOutput;
export type UpdateConnectionApiArg = {
  /** The presence of this header, coming from an account with the CAN_IMPERSONATE permission, will cause the request to be executed as if it were coming from the account with the id specified */
  'X-Impersonated-By'?: any;
  'connection-id': number;
  sourceInput: SourceInput;
};
export type DeleteConnectionApiResponse = unknown;
export type DeleteConnectionApiArg = {
  /** The presence of this header, coming from an account with the CAN_IMPERSONATE permission, will cause the request to be executed as if it were coming from the account with the id specified */
  'X-Impersonated-By'?: any;
  'connection-id': number;
  purge: boolean;
};
export type PurgeConnectionApiResponse = unknown;
export type PurgeConnectionApiArg = {
  /** The presence of this header, coming from an account with the CAN_IMPERSONATE permission, will cause the request to be executed as if it were coming from the account with the id specified */
  'X-Impersonated-By'?: any;
  'connection-id': number;
};
export type ListUnlinkedManualConnectionsApiResponse =
  /** status 200 listUnlinkedManualConnections 200 response */ AirbyteConnectionOutput[];
export type ListUnlinkedManualConnectionsApiArg = {
  'source-type': SourceType;
};
export type GetAccessTokenForServiceApiResponse =
  /** status 200 getAccessTokenForService 200 response */ GetCredentialResponse;
export type GetAccessTokenForServiceApiArg = {
  sourceType: SourceType;
  refreshToken: string;
};
export type GetCredentialsForServiceApiResponse =
  /** status 200 if everything goes OK */ GetCredentialResponse;
export type GetCredentialsForServiceApiArg = {
  sourceType: SourceType;
  redirectUrl: string;
  code: string;
};
export type WhoamiApiResponse =
  /** status 200 whoami 200 response */ MeResponse;
export type WhoamiApiArg = void;
export type GetOAuthUrlApiResponse = /** status 200 Ok */ GetOAuthUrlResponse;
export type GetOAuthUrlApiArg = {
  sourceType: SourceType;
  redirectUrl: string;
};
export type GetTenantApiResponse =
  /** status 200 getTenant 200 response */ GetTenantResponse;
export type GetTenantApiArg = void;
export type ListWorkspacesApiResponse =
  /** status 200 listWorkspaces 200 response */ WorkspaceOutput[];
export type ListWorkspacesApiArg = {
  /** The presence of this header, coming from an account with the CAN_IMPERSONATE permission, will cause the request to be executed as if it were coming from the account with the id specified */
  'X-Impersonated-By'?: any;
  'alternate-id'?: string | null;
};
export type CreateWorkspaceApiResponse =
  /** status 200 createWorkspace 200 response */ WorkspaceOutput;
export type CreateWorkspaceApiArg = {
  /** The presence of this header, coming from an account with the CAN_IMPERSONATE permission, will cause the request to be executed as if it were coming from the account with the id specified */
  'X-Impersonated-By'?: any;
  workspaceInput: WorkspaceInput;
};
export type GetWorkspaceByIdApiResponse =
  /** status 200 getWorkspaceById 200 response */ WorkspaceOutput;
export type GetWorkspaceByIdApiArg = {
  /** The presence of this header, coming from an account with the CAN_IMPERSONATE permission, will cause the request to be executed as if it were coming from the account with the id specified */
  'X-Impersonated-By'?: any;
  'workspace-id': number;
};
export type UpdateWorkspaceApiResponse =
  /** status 200 updateWorkspace 200 response */ WorkspaceOutput;
export type UpdateWorkspaceApiArg = {
  /** The presence of this header, coming from an account with the CAN_IMPERSONATE permission, will cause the request to be executed as if it were coming from the account with the id specified */
  'X-Impersonated-By'?: any;
  'workspace-id': number;
  workspaceInput: WorkspaceInput;
};
export type DeleteWorkspaceApiResponse = unknown;
export type DeleteWorkspaceApiArg = {
  /** The presence of this header, coming from an account with the CAN_IMPERSONATE permission, will cause the request to be executed as if it were coming from the account with the id specified */
  'X-Impersonated-By'?: any;
  'workspace-id': number;
};
export type ListConnectionsByWorkspaceApiResponse =
  /** status 200 listConnectionsByWorkspace 200 response */ ConnectionGroupsConnectionOutput;
export type ListConnectionsByWorkspaceApiArg = {
  /** The presence of this header, coming from an account with the CAN_IMPERSONATE permission, will cause the request to be executed as if it were coming from the account with the id specified */
  'X-Impersonated-By'?: any;
  'workspace-id': number;
};
export type CreateConnectionApiResponse =
  /** status 200 createConnection 200 response */ ConnectionOutput;
export type CreateConnectionApiArg = {
  /** The presence of this header, coming from an account with the CAN_IMPERSONATE permission, will cause the request to be executed as if it were coming from the account with the id specified */
  'X-Impersonated-By'?: any;
  'workspace-id': number;
  sourceInput: SourceInput;
};
export type GetConnectionInWorkspaceApiResponse =
  /** status 200 getConnectionInWorkspace 200 response */ ConnectionOutput;
export type GetConnectionInWorkspaceApiArg = {
  /** The presence of this header, coming from an account with the CAN_IMPERSONATE permission, will cause the request to be executed as if it were coming from the account with the id specified */
  'X-Impersonated-By'?: any;
  'workspace-id': number;
  'connection-id': number;
};
export type UpdateConnectionFromWorkspaceApiResponse =
  /** status 200 updateConnectionFromWorkspace 200 response */ ConnectionOutput;
export type UpdateConnectionFromWorkspaceApiArg = {
  /** The presence of this header, coming from an account with the CAN_IMPERSONATE permission, will cause the request to be executed as if it were coming from the account with the id specified */
  'X-Impersonated-By'?: any;
  'workspace-id': number;
  'connection-id': number;
  sourceInput: SourceInput;
};
export type DeleteConnectionFromWorkspaceApiResponse = unknown;
export type DeleteConnectionFromWorkspaceApiArg = {
  /** The presence of this header, coming from an account with the CAN_IMPERSONATE permission, will cause the request to be executed as if it were coming from the account with the id specified */
  'X-Impersonated-By'?: any;
  'workspace-id': number;
  'connection-id': number;
  purge: boolean;
};
export type PurgeConnectionInWorkspaceApiResponse = unknown;
export type PurgeConnectionInWorkspaceApiArg = {
  /** The presence of this header, coming from an account with the CAN_IMPERSONATE permission, will cause the request to be executed as if it were coming from the account with the id specified */
  'X-Impersonated-By'?: any;
  'workspace-id': number;
  'connection-id': number;
};
export type ListDashboardsByWorkspaceApiResponse =
  /** status 200 listDashboardsByWorkspace 200 response */ DashboardDto[];
export type ListDashboardsByWorkspaceApiArg = {
  /** The presence of this header, coming from an account with the CAN_IMPERSONATE permission, will cause the request to be executed as if it were coming from the account with the id specified */
  'X-Impersonated-By'?: any;
  'workspace-id': number;
};
export type ListDataSourcesByWorkspaceApiResponse =
  /** status 200 listDataSourcesByWorkspace 200 response */ DataSourceOutput[];
export type ListDataSourcesByWorkspaceApiArg = {
  /** The presence of this header, coming from an account with the CAN_IMPERSONATE permission, will cause the request to be executed as if it were coming from the account with the id specified */
  'X-Impersonated-By'?: any;
  'workspace-id': number;
};
export type ListManualConnectionsByWorkspaceApiResponse =
  /** status 200 listManualConnectionsByWorkspace 200 response */ ManualConnectionOutput[];
export type ListManualConnectionsByWorkspaceApiArg = {
  /** The presence of this header, coming from an account with the CAN_IMPERSONATE permission, will cause the request to be executed as if it were coming from the account with the id specified */
  'X-Impersonated-By'?: any;
  'workspace-id': number;
};
export type CreateManualConnectionApiResponse =
  /** status 200 createManualConnection 200 response */ ManualConnectionOutput;
export type CreateManualConnectionApiArg = {
  /** The presence of this header, coming from an account with the CAN_IMPERSONATE permission, will cause the request to be executed as if it were coming from the account with the id specified */
  'X-Impersonated-By'?: any;
  'workspace-id': number;
  manualConnectionInput: ManualConnectionInput;
};
export type UpdateManualConnectionFromWorkspaceApiResponse =
  /** status 200 updateManualConnectionFromWorkspace 200 response */ ManualConnectionOutput;
export type UpdateManualConnectionFromWorkspaceApiArg = {
  /** The presence of this header, coming from an account with the CAN_IMPERSONATE permission, will cause the request to be executed as if it were coming from the account with the id specified */
  'X-Impersonated-By'?: any;
  'workspace-id': number;
  'manual-connection-id': number;
  manualConnectionInput: ManualConnectionInput;
};
export type DeleteManualConnectionFromWorkspaceApiResponse = unknown;
export type DeleteManualConnectionFromWorkspaceApiArg = {
  /** The presence of this header, coming from an account with the CAN_IMPERSONATE permission, will cause the request to be executed as if it were coming from the account with the id specified */
  'X-Impersonated-By'?: any;
  'workspace-id': number;
  'manual-connection-id': number;
};
export type QueryWorkspaceApiResponse =
  /** status 200 queryWorkspace 200 response */ VisualizationQueryResult;
export type QueryWorkspaceApiArg = {
  /** The presence of this header, coming from an account with the CAN_IMPERSONATE permission, will cause the request to be executed as if it were coming from the account with the id specified */
  'X-Impersonated-By'?: any;
  'workspace-id': number;
  tag?: string | null;
  body: {
    query: VisualizationQuery;
  };
};
export type AccountDto = {
  name: string;
  id: string;
};
export type AccountMapDto = {
  customers: {
    [key: string]: AccountDto[];
  };
};
export type ErrorDto = {
  message: string;
  detail?: string | null;
};
export type SourceType =
  | 'BING_ADS'
  | 'CELTRA'
  | 'CROSS_CHANNEL'
  | 'CUSTOM_REPORTS'
  | 'FACEBOOK_MARKETING'
  | 'GOOGLE_ADS'
  | 'GOOGLE_ANALYTICS_GA4'
  | 'GOOGLE_BIG_QUERY'
  | 'GOOGLE_CAMPAIGN_MANAGER'
  | 'GOOGLE_SEARCH_CONSOLE'
  | 'GOOGLE_SHEETS'
  | 'KORTX'
  | 'LINKEDIN_ADS'
  | 'PINTEREST'
  | 'SALESFORCE'
  | 'SNAPCHAT_MARKETING'
  | 'TIKTOK_MARKETING'
  | 'TWITTER'
  | 'XANDR'
  | 'YELP'
  | 'YOUTUBE_ANALYTICS';
export type ConnectionOutput = {
  id: number;
  externalUuid: string;
  advertiserId?: string | null;
  workspaceId: number;
  name: string;
  sourceId: number;
  sourceName: string;
  sourceType: SourceType;
  destinationId: number;
  createdDate: string;
  modifiedDate: string;
};
export type BingAdsSourceInput = {
  name: string;
  reportsStartDate: string;
  refreshToken: string;
  type: SourceType;
};
export type FbMarketingSourceInput = {
  name: string;
  accountId: string;
  startDate: string;
  accessToken: string;
  type: SourceType;
};
export type GoogleAdsSourceInput = {
  name: string;
  conversionWindowDays: number;
  startDate: string;
  loginCustomerId: string;
  customerId: string;
  refreshToken: string;
  type: SourceType;
};
export type GoogleAnalyticsGa4SourceInput = {
  name: string;
  dateRangesStartDate: string;
  refreshToken: string;
  propertyId: string;
  windowInDays: number;
  type: SourceType;
};
export type GoogleSearchConsoleSourceInput = {
  name: string;
  siteUrl: string;
  startDate: string;
  refreshToken: string;
  unused?: any | null;
  type: SourceType;
};
export type YouTubeSourceInput = {
  name: string;
  siteUrl: string;
  startDate: string;
  refreshToken: string;
  type: SourceType;
};
export type SourceInput =
  | BingAdsSourceInput
  | FbMarketingSourceInput
  | GoogleAdsSourceInput
  | GoogleAnalyticsGa4SourceInput
  | GoogleSearchConsoleSourceInput
  | YouTubeSourceInput;
export type AirbyteConnectionOutput = {
  connectionId: string;
  connectionName: string;
  workspaceId: string;
  sourceId: string;
};
export type ExpiringToken = {
  token: string;
  expiresInSeconds: number;
};
export type GetCredentialResponse = {
  refreshToken?: string | null;
  accessToken?: ExpiringToken | null;
};
export type ValidationErrorDto = {
  message: string;
  violations: {
    [key: string]: string;
  };
};
export type StackTraceElement = {
  fileName: string;
  lineNumber: number;
  moduleName?: string;
  moduleVersion?: string;
  classLoaderName?: string;
  className?: string;
  methodName: string;
  nativeMethod?: boolean;
};
export type Throwable = {
  message?: string;
  localizedMessage?: string;
  cause?: Throwable;
  stackTrace?: StackTraceElement[];
  suppressed?: Throwable[];
};
export type MeResponse = {
  isLoggedIn: boolean;
  username: string;
  roles: string[];
};
export type GetOAuthUrlResponse = {
  url: string;
};
export type Principal = {
  name?: string;
};
export type Authentication = Principal & {
  attributes: {
    [key: string]: any;
  };
  roles: string[];
};
export type GetTenantResponse = {
  tenantId: string;
  authentication?: Authentication | null;
};
export type WorkspaceOutput = {
  id: number;
  externalUuid: string;
  alternateId: string;
  name: string;
  description: string;
  createdDate: string;
  modifiedDate: string;
};
export type WorkspaceInput = {
  name: string;
  description: string;
  alternateId: string;
};
export type ConnectionGroupState = 'CREATED' | 'ROLLUP_DONE' | 'ROLLUP_FAILED';
export type ConnectionGroupOutput = {
  state: ConnectionGroupState;
  lastCompletedDate?: string | null;
};
export type ConnectionGroupsConnectionOutput = {
  groups: {
    [key: string]: ConnectionGroupOutput;
  };
  connections: ConnectionOutput[];
};
export type DashboardDto = {
  id: string;
  name: string;
  url: string;
  sourceTypes: SourceType[];
};
export type DataSourceOutput = {
  sourceType: SourceType;
  state: ConnectionGroupState;
  lastCompletedDate?: string | null;
};
export type ManualConnectionOutput = {
  id: number;
  externalUuid: string;
  workspaceId: number;
  sourceType: SourceType;
  createdDate: string;
  modifiedDate: string;
};
export type ManualConnectionInput = {
  externalUuid: string;
  sourceType: SourceType;
};
export type VisualizationQueryResultField = {
  name: string;
  type: string;
};
export type VisualizationQueryResult = {
  fields: VisualizationQueryResultField[];
  rows: {
    [key: string]: any;
  }[];
};
export type DynamicField = {
  category: string;
  expression?: string | null;
  label?: string | null;
  valueFormat?: string | null;
  valueFormatName?: string | null;
  basedOn?: string | null;
  measure?: string | null;
  type?: string | null;
  tableCalculation?: string | null;
};
export type VisualizationQuery = {
  view: string;
  fields: string[];
  filters: {
    [key: string]: string;
  };
  sorts: string[];
  dynamicFields: DynamicField[];
  limit: number;
};
export const {
  useGetAccountsQuery,
  useGetConnectionByIdQuery,
  useUpdateConnectionMutation,
  useDeleteConnectionMutation,
  usePurgeConnectionMutation,
  useListUnlinkedManualConnectionsQuery,
  useGetAccessTokenForServiceQuery,
  useGetCredentialsForServiceQuery,
  useWhoamiQuery,
  useGetOAuthUrlQuery,
  useGetTenantQuery,
  useListWorkspacesQuery,
  useCreateWorkspaceMutation,
  useGetWorkspaceByIdQuery,
  useUpdateWorkspaceMutation,
  useDeleteWorkspaceMutation,
  useListConnectionsByWorkspaceQuery,
  useCreateConnectionMutation,
  useGetConnectionInWorkspaceQuery,
  useUpdateConnectionFromWorkspaceMutation,
  useDeleteConnectionFromWorkspaceMutation,
  usePurgeConnectionInWorkspaceMutation,
  useListDashboardsByWorkspaceQuery,
  useListDataSourcesByWorkspaceQuery,
  useListManualConnectionsByWorkspaceQuery,
  useCreateManualConnectionMutation,
  useUpdateManualConnectionFromWorkspaceMutation,
  useDeleteManualConnectionFromWorkspaceMutation,
  useQueryWorkspaceMutation,
} = injectedRtkApi;
