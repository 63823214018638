import { useMemo } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import KortxLogoSVG from 'assets/kortx/logo.svg?react';
import Columns from 'components/shared/Layout/Columns';

export type KampusBadgeProps = {
  size?: 'small' | 'medium' | 'large';
  linkTo?: string;
  showLogotype?: boolean;
};

export default function KampusBadge({
  linkTo,
  size = 'medium',
  showLogotype = false,
}: KampusBadgeProps) {
  const logoSizeAsRem = useMemo(() => {
    switch (size) {
      case 'small':
        return '2rem';
      case 'medium':
        return '2.5rem';
      case 'large':
        return '4rem';
      default:
        return undefined;
    }
  }, [size]);

  return (
    <Columns sx={{ py: 3, alignItems: 'center' }}>
      {linkTo ? (
        <Link display="flex" href={linkTo}>
          <KortxLogoSVG width={logoSizeAsRem} height={logoSizeAsRem} />
        </Link>
      ) : (
        <Box display="flex">
          <KortxLogoSVG width={logoSizeAsRem} height={logoSizeAsRem} />
        </Box>
      )}
      {showLogotype && (
        <Box
          sx={{
            borderLeft: (theme) => `1px solid ${theme.palette.grey[500]}`,
            px: 2,
            ml: 2,
            py: 0.2,
          }}
        >
          <Typography variant="kampus" color="white">
            Kampus
          </Typography>
        </Box>
      )}
    </Columns>
  );
}
